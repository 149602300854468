<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <div class="d-flex align-items-center">
      <b-form-group class="w-50 pr-50">
        <v-select
          @input="updateSelected"
          v-model="ivr_info"
          :options="options"
          :placeholder="lang('t_IVR')"
          :reduce="(option) => option"
          label="display_name"
        >
          <template v-slot:option="option">
            <span v-if="option.internal_name == 'new_record'">
              <feather-icon icon="PlusIcon" />
              {{ lang("t_addNewRecord") }}
            </span>
            <span v-else>{{ option.display_name }}</span>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group class="w-25 pl-50">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="EditIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="ivr_info.display_name"
            :placeholder="lang('t_displayName')"
            id="ivr"
            trim
          />
        </b-input-group>
      </b-form-group>

      <b-form-group class="ml-auto">
        <b-button
          v-b-tooltip.hover.v-secondary
          v-b-tooltip.hover.top="lang('t_save')"
          @click="set_record"
          size="sm"
          class="px-50 py-50 float-right"
          variant="flat-secondary"
        >
          <i class="font-medium-1 bi bi-floppy"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover.v-secondary
          v-b-tooltip.hover.top="lang('t_copyFromTheEditor')"
          @click="copy_editor"
          size="sm"
          class="px-50 py-50 float-right"
          variant="flat-secondary"
        >
          <i class="font-medium-1 bi bi-clipboard"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover.v-secondary
          v-b-tooltip.hover.top="lang('t_pasteIntoTheEditor')"
          @click="paste_editor"
          size="sm"
          class="px-50 py-50 float-right"
          variant="flat-secondary"
        >
          <i class="font-medium-1 bi bi-clipboard-check"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover.v-secondary
          v-b-tooltip.hover.top="lang('t_duplicate')"
          v-if="ivr_info.internal_name != 'new_record'"
          @click="modal_copy = true"
          size="sm"
          class="px-50 py-50 float-right"
          variant="flat-secondary"
        >
          <i class="font-medium-1 bi bi-copy"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover.v-secondary
          v-b-tooltip.hover.top="lang('t_delete')"
          v-if="ivr_info.internal_name != 'new_record'"
          @click="delete_request"
          size="sm"
          class="px-50 py-50 float-right"
          variant="flat-secondary"
        >
          <i class="font-medium-1 bi bi-trash3"></i>
        </b-button>
        <b-button
          v-b-tooltip.hover.v-secondary
          v-b-tooltip.hover.top="lang('t_fullScreen')"
          @click="ShowFullscreen"
          size="sm"
          class="px-50 py-50 float-right"
          variant="flat-secondary"
        >
          <i class="font-medium-1 bi bi-fullscreen"></i>
        </b-button>
        <!-- <b-button
          @click="set_record"
          class="float-right ml-1"
          variant="flat-primary"
          >{{ lang("t_save") }}</b-button -->

        <!-- <b-button
          v-if="ivr_info.internal_name != 'new_record'"
          @click="delete_request"
          class="float-right"
          variant="flat-danger"
          >{{ lang("t_delete") }}</b-button
        > -->
      </b-form-group>
    </div>

    <div no-body id="editor">
      <b-row class="mx-0 hint">
        <!-- <b-button variant="flat-primary" @click="goToStart">Go To Start</b-button> -->
        <vue-slider
          style="width: 7vw"
          :min="10"
          :max="500"
          :interval="10"
          :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          @change="changeScale"
          v-model="editor_scale"
        />
        <span class="text-muted"> {{ editor_scale }}</span>

        <!-- <b-button size="sm" variant="flat-primary" @click="resetScale">
          <feather-icon icon="GitBranchIcon"></feather-icon>
        </b-button> -->
        <b-dropdown
          boundary="viewport"
          menu-class="d-menu-class"
          text="Go"
          variant="flat-primary"
          size="sm"
        >
          <b-dropdown-item
            @click="goToNode(item)"
            v-for="item in editor.nodes"
            :key="item.id"
            >{{ item.name }}</b-dropdown-item
          >
        </b-dropdown>
      </b-row>

      <baklava-editor :plugin="viewPlugin"> </baklava-editor>
    </div>

    <b-modal
      centered
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      v-if="![null, '', undefined].includes(selected_item)"
      v-model="edit_modal"
      :title="selected_item.display_name"
      @hide="temp = null"
    >
      <!-- <b-card-title class="headline">
        <h3>{{ selected_item.display_name }}</h3>
      </b-card-title> -->
      <!-- :title="selected_item.display_name" -->

      <b-row
        v-if="
          selected_item.name == 'sms' ||
          selected_item.name == 'tts' ||
          selected_item.name == 'variable' ||
          selected_item.name == 'condition' ||
          selected_item.name == 'http'
        "
        class="mb-1"
      >
        <b-col>
          <h4>{{ lang("t_useableVariables") }}</h4>

          <b-badge
            class="mr-25 cursor-point"
            variant="primary"
            @click="doCopy('RemoteNumber')"
          >
            {{ "RemoteNumber" }}
          </b-badge>
          <b-badge
            class="mx-25 cursor-point"
            variant="primary"
            @click="doCopy('InteractionID')"
          >
            {{ "InteractionID" }}
          </b-badge>
          <b-badge
            class="mx-25 cursor-point"
            variant="primary"
            v-for="(item, i) in list
              .filter((e) => e.name == 'menu')
              .map((e) => e.tasks)
              .flat()
              .filter((e) =>
                ['variable', 'tts', 'http', 'sms', 'condition', 'stt'].includes(
                  e.name
                )
              )
              .filter((e) => e.variable && e.variable.length > 0)"
            :key="i"
            @click="doCopy(item.variable)"
          >
            {{ item.variable }}
          </b-badge>
        </b-col>
      </b-row>
      <b-form-checkbox
        class="mb-1"
        v-if="selected_item.name == 'variable'"
        v-model="selected_item.is_set_attribute"
        switch
        inline
      >
        {{ lang("t_isSetAttribute") }}
      </b-form-checkbox>

      <b-form-group
        v-if="
          ['condition', 'variable', 'http', 'tts', 'sms', 'stt'].includes(
            selected_item.name
          )
        "
        :label="lang('t_description')"
        label-for="t_description"
      >
        <b-form-input
          v-model="selected_item.description"
          trim
          :placeholder="lang('t_description')"
        />
      </b-form-group>
      <b-form-group
        v-if="
          selected_item.parent == 'menu' &&
          selected_item.name != 'announce' &&
          !selected_item.is_set_attribute
        "
        :label="lang('t_DTMFdigit')"
      >
        <b-form-input
          v-model="selected_item.dtmf"
          trim
          :placeholder="lang('t_DTMFdigit')"
        />
      </b-form-group>

      <b-form-input
        class="mt-1"
        v-if="selected_item.name == 'transfer'"
        v-model="selected_item.target.internal_name"
        trim
        :placeholder="lang('t_number')"
      />
      <v-select
        class="mt-1"
        v-if="selected_item.name == 'transfer'"
        v-model="selected_item.line_group"
        :options="line_groups"
        :placeholder="lang('t_lineGroup')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>
      <v-select
        class="mt-1"
        v-if="selected_item.name == 'queue'"
        v-model="selected_item.target"
        :options="queues"
        :placeholder="lang('t_queue')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>
      <v-select
        v-if="selected_item.name == 'announce'"
        v-model="selected_item.target"
        :options="announcements"
        :placeholder="lang('t_announcementFile')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>
      <b-form-checkbox
        class="mt-1"
        v-if="
          selected_item.name == 'announce' && selected_item.parent == 'menu'
        "
        v-model="selected_item.can_recieve_dtmf"
        switch
        inline
      >
        {{ lang("t_DTMFAccept") }}
      </b-form-checkbox>

      <b-form-group
        v-if="selected_item.name == 'survey'"
        :label="lang('t_announcementFile')"
      >
        <v-select
          v-model="selected_item.announcement"
          :options="announcements"
          :placeholder="lang('t_announcementFile')"
          :reduce="(option) => option"
          label="display_name"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        v-if="selected_item.name == 'survey'"
        :label="lang('t_survey')"
      >
        <v-select
          v-model="selected_item.target"
          :options="surveys"
          :placeholder="lang('t_survey')"
          :reduce="(option) => option"
          label="display_name"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="
          selected_item.name == 'variable' && !selected_item.is_set_attribute
        "
        :label="lang('t_announcementFile')"
      >
        <v-select
          v-model="selected_item.target"
          :options="announcements"
          :placeholder="lang('t_announcementFile')"
          :reduce="(option) => option"
          label="display_name"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        v-if="
          selected_item.name == 'variable' && !selected_item.is_set_attribute
        "
        :label="lang('t_timeOutLengthSec')"
      >
        <b-form-input
          v-model="selected_item.timeout"
          trim
          :placeholder="lang('t_timeOutLengthSec')"
        />
      </b-form-group>
      <b-form-group
        v-if="
          selected_item.name == 'variable' && !selected_item.is_set_attribute
        "
        :label="lang('t_maxInvalidInputCount')"
      >
        <b-form-input
          v-model="selected_item.retry"
          trim
          :placeholder="lang('t_maxInvalidInputCount')"
          type="number"
        />
      </b-form-group>
      <b-form-group
        v-if="selected_item.name == 'variable'"
        :label="lang('t_variable')"
      >
        <b-form-input
          v-model="selected_item.variable"
          trim
          :placeholder="lang('t_variable')"
        />
      </b-form-group>
      <b-form-group
        v-if="selected_item.name == 'variable'"
        :label="lang('t_value')"
      >
        <b-form-input
          v-model="selected_item.value"
          trim
          :placeholder="lang('t_value')"
        />
      </b-form-group>

      <b-form-group
        v-if="
          selected_item.name == 'variable' && !selected_item.is_set_attribute
        "
        :label="lang('t_variableLength')"
      >
        <b-form-input
          v-model="selected_item.variable_length"
          trim
          :placeholder="lang('t_variableLength')"
        />
      </b-form-group>

      <b-form-checkbox
        v-if="
          selected_item.name == 'variable' && !selected_item.is_set_attribute
        "
        class="mt-1"
        v-model="selected_item.hide_variable_from_agent"
        switch
        inline
      >
        {{ lang("t_hideVariableFromAgent") }}
      </b-form-checkbox>

      <!-- HTTP -->
      <b-form-group
        v-if="selected_item.name == 'http'"
        :label="lang('t_variable')"
      >
        <b-form-input
          v-model="selected_item.variable"
          trim
          :placeholder="lang('t_variable')"
        />
        <b-form-checkbox
          class="mt-1"
          v-model="selected_item.hide_variable_from_agent"
          switch
          inline
        >
          {{ lang("t_hideVariableFromAgent") }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group v-if="selected_item.name == 'http'" :label="lang('t_type')">
        <v-select
          v-model="selected_item.type"
          :options="['GET', 'POST', 'PUT', 'DELETE']"
          :placeholder="lang('t_type')"
        >
        </v-select>
      </b-form-group>
      <b-form-group v-if="selected_item.name == 'http'" :label="lang('t_url')">
        <b-form-input
          v-model="selected_item.url"
          trim
          :placeholder="lang('t_url')"
        />
      </b-form-group>
      <b-form-group
        v-if="selected_item.name == 'http'"
        :label="lang('t_headers')"
      >
        <b-form-textarea
          rows="3"
          v-model="selected_item.headers"
          trim
          :placeholder="lang('t_headers')"
        />
      </b-form-group>
      <b-form-group v-if="selected_item.name == 'http'" :label="lang('t_body')">
        <b-form-textarea
          rows="5"
          v-model="selected_item.body"
          trim
          :placeholder="lang('t_body')"
        />
      </b-form-group>

      <!-- --- -->

      <!-- TTS -->

      <b-form-group v-if="selected_item.name == 'tts'" :label="lang('t_type')">
        <v-select
          v-model="selected_item.type"
          :options="tts_providers"
          :reduce="(option) => option"
          label="display_name"
          :placeholder="lang('t_type')"
        >
        </v-select>
      </b-form-group>
      <b-form-group v-if="selected_item.name == 'tts'" :label="lang('t_text')">
        <b-form-textarea
          rows="7"
          v-model="selected_item.message"
          trim
          :placeholder="lang('t_text')"
        />
      </b-form-group>

      <!-- --- -->

      <!-- STT -->

      <b-form-group
        v-if="selected_item.name == 'stt'"
        :label="lang('t_provider')"
      >
        <v-select
          v-model="selected_item.target"
          :options="stt_providers"
          :reduce="(option) => option"
          label="display_name"
          :placeholder="lang('t_provider')"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        v-if="selected_item.name == 'stt'"
        :label="lang('t_timeOutLengthSec')"
      >
        <b-form-input
          v-model="selected_item.timeout"
          trim
          :placeholder="lang('t_timeOutLengthSec')"
        />
      </b-form-group>
      <b-form-group
        v-if="selected_item.name == 'stt'"
        :label="lang('t_variable')"
      >
        <b-form-input
          v-model="selected_item.variable"
          trim
          :placeholder="lang('t_variable')"
        />
      </b-form-group>
      <b-form-group v-if="selected_item.name == 'stt'">
        <b-form-checkbox v-model="selected_item.beep_tone" switch inline>
          {{ lang("t_beepTone") }}
        </b-form-checkbox>
      </b-form-group>

      <!-- --- -->

      <!-- SMS -->

      <b-form-group
        v-if="selected_item.name == 'sms'"
        :label="lang('t_operator')"
        label-for="t_operator"
      >
        <v-select
          v-model="selected_item.sms_settings"
          :options="sms_settings"
          label="display_name"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="selected_item.name == 'sms'"
        :label="lang('t_remoteNumber')"
        label-for="t_remoteNumber"
      >
        <b-form-input
          v-model="selected_item.remote_number"
          trim
          :placeholder="lang('t_remoteNumber')"
        />
      </b-form-group>

      <b-form-group
        v-if="selected_item.name == 'sms'"
        :label="lang('t_text')"
        label-for="t_text"
      >
        <b-form-textarea
          rows="7"
          v-model="selected_item.message"
          trim
          :placeholder="lang('t_text')"
        />
      </b-form-group>

      <!-- --- -->

      <!-- CONDITION -->

      <b-row v-if="selected_item.name == 'condition'">
        <b-col cols="6">
          <b-form-input
            class="mt-1"
            v-model="selected_item.condition.value1"
            trim
            v-if="selected_item.name == 'condition'"
            :placeholder="lang('t_rule')"
          />
        </b-col>
        <b-col cols="6">
          <v-select
            v-if="selected_item.name == 'condition'"
            class="mt-1"
            label="display_name"
            :reduce="(val) => val.internal_name"
            :options="operands"
            v-model="selected_item.condition.operand"
          />
        </b-col>
        <b-col cols="12">
          <b-form-input
            class="mt-1"
            v-model="selected_item.condition.value2"
            trim
            v-if="selected_item.name == 'condition'"
            :placeholder="lang('t_value')"
          />
        </b-col>
      </b-row>

      <!-- --- -->
      <v-select
        v-model="selected_item.target"
        v-if="selected_item.name == 'tc'"
        :options="tc"
        :placeholder="lang('t_timeCondition')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>
      <b-form-group v-if="selected_item.name == 'exten'" label-for="type">
        <b-row>
          <b-col>
            <b-form-radio
              @input="
                () => {
                  selected_item.target = null;
                }
              "
              v-model="selected_item.type"
              value="exten"
              class="custom-control-primary"
            >
              {{ lang("t_extension") }}
            </b-form-radio>
          </b-col>
          <b-col>
            <b-form-radio
              @input="
                () => {
                  selected_item.target = null;
                }
              "
              v-model="selected_item.type"
              value="voice_mail"
              class="custom-control-primary"
            >
              {{ lang("t_qVoiceMail") }}
            </b-form-radio>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        v-if="selected_item.name == 'exten' && selected_item.type == 'exten'"
        :label="lang('t_extension')"
      >
        <v-select
          v-model="selected_item.target"
          :options="local_users"
          :placeholder="lang('t_extension')"
          :reduce="(option) => option"
          label="display_name"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="
          selected_item.name == 'exten' && selected_item.type == 'voice_mail'
        "
        :label="lang('t_eMail')"
      >
        <v-select
          multiple
          v-model="selected_item.target"
          :options="voice_mail_users"
          :placeholder="lang('t_eMail')"
          :reduce="(option) => option"
          label="display_name"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="
          selected_item.name == 'exten' && selected_item.type == 'voice_mail'
        "
        :label="lang('t_account')"
      >
        <v-select
          v-model="selected_item.mail_settings"
          :options="email_settings"
          :placeholder="lang('t_account')"
          :reduce="(option) => option"
          label="display_name"
        >
        </v-select>
      </b-form-group>

      <b-form-group v-if="selected_item.name == 'ivr'" :label="lang('t_IVR')">
        <v-select
          v-model="temp"
          :options="ivr"
          :placeholder="lang('t_IVR')"
          :reduce="(option) => option"
          label="display_name"
          @input="on_change_ivr(selected_item.target)"
        >
        </v-select>
      </b-form-group>
      <v-select
        v-model="selected_item.language"
        v-if="selected_item.name == 'menu'"
        :options="languages"
        :placeholder="lang('t_language')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>
      <b-form-input
        class="mt-1"
        v-model="selected_item.timeout"
        trim
        v-if="selected_item.name == 'menu'"
        :placeholder="lang('t_timeOutLengthSec')"
      />
      <!-- <b-form-input
        class="mt-1"
        v-model="selected_item.timeout_retry"
        trim
        v-if="selected_item.name == 'menu'"
        :placeholder="lang('t_timeOutCount')"
        type="number"
      /> -->
      <b-form-input
        class="mt-1"
        v-model="selected_item.retry"
        trim
        v-if="selected_item.name == 'menu'"
        :placeholder="lang('t_maxInvalidInputCount')"
        type="number"
      />
      <b-form-checkbox
        class="mt-1"
        v-if="selected_item.name == 'menu'"
        v-model="selected_item.can_recieve_exten"
        switch
        inline
      >
        {{ lang("t_acceptDTMFExtension") }}
      </b-form-checkbox>
      <b-button
        class="mt-1 float-right"
        variant="primary"
        @click="
          edit_modal = false;
          request_verify(selected_item);
        "
        >{{ lang("t_confirm") }}</b-button
      >
    </b-modal>
    <b-modal
      :title="lang('t_copy')"
      @ok="copy_as_new_ivr"
      centered
      v-model="modal_copy"
    >
      <b-form-input
        v-model="copy_name"
        :placeholder="lang('t_displayName')"
      ></b-form-input>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import nestedDraggable from "./NestedDraggable";
import draggable from "vuedraggable";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Editor, NodeBuilder } from "@baklavajs/core";
import { OptionPlugin } from '@baklavajs/plugin-options-vue';
import { ViewPlugin } from "@baklavajs/plugin-renderer-vue";
import VSelect from './Options/VSelect.vue'
import TextAreaComponent from './Options/TextArea.vue'
import cButtonComponent from './Options/cButton.vue'
import cPlayButtonComponent from './Options/cPlayButton.vue'
import VueSlider from 'vue-slider-component'

import {
  StartNode,
  AssignAgentNode,
  MenuNode,
  TimeConditionNode,
  HangupNode,
  AnnouncementNode,
  QueueNode,
  MailNode,
  HTTPNode,
  ConditionNode,
  ExtensionNode,
  IVRNode,
  SMSNode,
  SurveyNode,
  TransferNode,
  TTSNode,
  VariableNode,
  VoiceMailNode,
  STTNode,
  SetAttributeNode,
} from './Nodes/index.js';
import {
  VBTooltip,
  BCardTitle,
  BFormCheckbox,
  VBModal,
  BModal,
  BButton,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BFormGroup,
  BCard,
  BCardText,
  BLink,
  BFormTextarea,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BInputGroup, BInputGroupPrepend,
} from "bootstrap-vue";

function formatData(data) {
  return data.map(item => ({
    text: item.display_name,
    value: JSON.stringify(item)
  }));
}

export default {

  components: {
    VBTooltip,
    BInputGroup, BInputGroupPrepend,
    VueSlider,
    VSelect,
    StartNode,
    AssignAgentNode,
    MenuNode,
    TimeConditionNode,
    MailNode,
    HangupNode,
    AnnouncementNode,
    QueueNode,
    HTTPNode,
    ConditionNode,
    ExtensionNode,
    IVRNode,
    SMSNode,
    SurveyNode,
    TransferNode,
    TTSNode,
    VariableNode,
    VoiceMailNode,
    STTNode,
    SetAttributeNode,
    Editor,
    NodeBuilder,
    OptionPlugin,
    ViewPlugin,
    ToastificationContent,
    BFormCheckbox,
    BCardTitle,
    nestedDraggable,
    BModal,
    BButton,
    BRow,
    BCol,
    BBadge,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    draggable,
    BFormRadio,
    BDropdown,
    BDropdownItem,

  },
  directives: {
    "b-modal": VBModal,
    'b-tooltip': VBTooltip,

  },
  created() {
    this.viewPlugin.enableMinimap = true;
    this.editor.use(this.viewPlugin);
    this.editor.use(new OptionPlugin());
    this.viewPlugin.registerOption("VSelect", VSelect)
    this.viewPlugin.registerOption("TextArea", TextAreaComponent);
    this.viewPlugin.registerOption("cButton", cButtonComponent);
    this.viewPlugin.registerOption("cPlayButton", cPlayButtonComponent);
    // register your nodes, node options, node interface types, ...

    this.editor.registerNodeType("AnnouncementNode", AnnouncementNode);
    this.editor.registerNodeType("AssignAgentNode", AssignAgentNode);
    this.editor.registerNodeType("ConditionNode", ConditionNode);
    this.editor.registerNodeType("ExtensionNode", ExtensionNode);
    this.editor.registerNodeType("HangupNode", HangupNode);
    this.editor.registerNodeType("HTTPNode", HTTPNode);
    this.editor.registerNodeType("IVRNode", IVRNode);
    this.editor.registerNodeType("MailNode", MailNode);
    this.editor.registerNodeType("MenuNode", MenuNode);
    this.editor.registerNodeType("QueueNode", QueueNode);
    this.editor.registerNodeType("SMSNode", SMSNode);
    this.editor.registerNodeType("STTNode", STTNode);
    this.editor.registerNodeType("SetAttributeNode", SetAttributeNode);
    this.editor.registerNodeType("StartNode", StartNode);
    this.editor.registerNodeType("SurveyNode", SurveyNode);
    this.editor.registerNodeType("TTSNode", TTSNode);
    this.editor.registerNodeType("TimeConditionNode", TimeConditionNode);
    this.editor.registerNodeType("TransferNode", TransferNode);
    this.editor.registerNodeType("VariableNode", VariableNode);
    this.editor.registerNodeType("VoiceMailNode", VoiceMailNode);


    this.editor.addNode(new StartNode());

    // this.editor.events.addNode.addListener("addNode", (e) => {
    //   console.log("addNode", e);

    //   if (this.editor.nodes.find(j => j.name == e.name)) {
    //     console.log("geldi")
    //     let _node = this.editor.nodes.find(j => j.id == e.id)
    //     _node.position.x = 500;
    //     _node.position.y = 200;
    //     console.log("addNode", e);
    //   }
    // });
    this.editor.events.beforeAddNode.addListener("beforeAddNode", (e) => {
      console.log("beforeAddNode", e);



      if (e.type == 'StartNode' && this.editor.nodes.find(j => j.type == 'StartNode')) {
        return false;
      }
      if (e.type == "HTTPNode") {

      }
      // if (e.type == "VariableNode") {
      //   e.options.get("Is Set Attribute").events.beforeSetValue.addListener("beforeSetValue", (j) => {

      //     if (j) {
      //       for (const key of e.options.keys()) {
      //         if (!['Is Set Attribute', 'Variable', 'Value'].includes(key)) {
      //           e.options.delete(key)
      //         }
      //       }
      //     }
      //     console.log("beforeSetValue", e)

      //   });
      // }
      if (Object.keys(this.IVRElements).includes(e.type)) {
        e.options.get(e.type.replace('Node', '')).items = this.IVRElements[e.type];
      }
      if (e.type == 'MailNode') {
        e.options.get('Provider').items = this.IVRElements.EMailProvidersNode;
        e.options.get('Template').items = this.IVRElements.EMailTemplatesNode;
      }
    });
    this.editor.events.addConnection.addListener("addConnection", (e) => {
      console.log("addConnection", e)



      console.log("e.from.parent", e.from.parent.type)
      if (e.from.parent.type == "AnnouncementNode" && e.from.parent.options.get("Accept DTMF")._value) {
        if (!e.to.parent.options.has("DTMF Digit")) {
          e.to.parent.addOption("DTMF Digit", "InputOption")
        }
      } else {
        if (e.to.parent.options.has("DTMF Digit")) {
          e.to.parent.options.delete("DTMF Digit");
        }
      }
    });
    this.editor.events.beforeAddConnection.addListener("beforeAddConnection", (e) => {

      console.log("DEBUGe", e);
      // console.log("DEBUG", e.to.parent.interfaces.get("In")._connectionCount);
      // console.log("DEBUG2", e.to.parent.interfaces.get("In").connectionCount);
      // if (e.to && e.to._connectionCount > 0) {
      //   return false;
      // }

      if (e.from.parent.type == 'AnnouncementNode' && e.from.parent.options.get('Accept DTMF').value) {
        return true;
      }
      const existingConnectionsFromOutput = this.editor.connections.filter(conn => conn.from.id === e.from.id);

      if (existingConnectionsFromOutput.length > 0) {
        console.log("Bu OutputInterface'den zaten bir bağlantı var. İkinci bir bağlantıya izin verilmiyor.");
        return false;
      }
    });

    globalThis.editor = this.editor;
  },

  methods: {
    copy_editor() {
      globalThis.LayoutContentRendererDefault.doCopy(JSON.stringify(globalThis.editor.save()));
    },
    async paste_editor() {
      try {
        const clipboardText = await navigator.clipboard.readText();
        globalThis.editor.load(JSON.parse(clipboardText));
      } catch (error) {

      }

    },
    ShowFullscreen() {
      var elem = document.getElementById("editor");

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    changeScale() {
      this._ivr_object = this.editor.save();

      this.editor.load({ ...this.editor.save(), scaling: this.editor_scale / 100 });
      for (const item of this._ivr_object.nodes) {
        if (item.type == 'HTTPNode') {
          console.log("item", item);
          for (const key of item.options) {
            console.log("key", key);
            if (['Header', 'Body'].includes(key[0])) {
              console.log("this.editor.nodes.find((e) => e.id == item.id)", this.editor.nodes.find((e) => e.id == item.id));
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
            }
            else if (key[0] == 'cButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        if (['AnnouncementNode', 'VariableNode', 'SurveyNode', 'QuestionNode', 'HTTPNode'].includes(item.type)) {
          console.log("item", item);
          for (const key of item.options) {
            if (key[0] == 'cPlayButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        for (const key of item.options) {

          if (['DTMF Digit', 'Value', 'Text'].includes(key[0]) && this.editor.nodes.find((e) => e.id == item.id).options.get(key[0])) {
            this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
          }
        }
      }
    },
    resetScale() {
      let nodeXPositions = this.editor.nodes.map(e => e.position.x);
      let maxX = Math.max(...nodeXPositions);
      let minX = Math.min(...nodeXPositions);

      let nodeYPositions = this.editor.nodes.map(e => e.position.y);
      let maxY = Math.max(...nodeYPositions);
      let minY = Math.min(...nodeYPositions);

      this._ivr_object = this.editor.save();

      this.editor.load({ ...this.editor.save(), panning: { x: -1 * ((maxX + minX) / 2), y: -1 * ((maxY + minY) / 2) }, scaling: 0.2 });

      for (const item of this._ivr_object.nodes) {
        if (item.type == 'HTTPNode') {
          console.log("item", item);
          for (const key of item.options) {
            console.log("key", key);
            if (['Header', 'Body'].includes(key[0])) {
              console.log("this.editor.nodes.find((e) => e.id == item.id)", this.editor.nodes.find((e) => e.id == item.id));
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
            }
            else if (key[0] == 'cButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        if (['AnnouncementNode', 'VariableNode', 'SurveyNode', 'QuestionNode', 'HTTPNode'].includes(item.type)) {
          console.log("item", item);
          for (const key of item.options) {
            if (key[0] == 'cPlayButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        for (const key of item.options) {

          if (['DTMF Digit', 'Value', 'Text'].includes(key[0]) && this.editor.nodes.find((e) => e.id == item.id).options.get(key[0])) {
            this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
          }
        }
      }
      this.editor_scale = Math.floor(1 * 0.2);
    },
    setScaleInterval() {

      let tmp = this.editor.save();
      this._ivr_object = tmp;

      this.editor_scale = Math.floor(tmp.scaling * 100);
      if (this.editor_scale >= 500) {
        this.editor_scale = 500;
        this.editor.load({ ...this.editor.save(), scaling: 5 });
      }
      if (this.editor_scale <= 10) {
        this.editor_scale = 10;
        this.editor.load({ ...this.editor.save(), scaling: 0.1 });
      }
      for (const item of this._ivr_object.nodes) {
        if (item.type == 'HTTPNode') {
          console.log("item", item);
          for (const key of item.options) {
            console.log("key", key);
            if (['Header', 'Body'].includes(key[0])) {
              console.log("this.editor.nodes.find((e) => e.id == item.id)", this.editor.nodes.find((e) => e.id == item.id));
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
            }
            else if (key[0] == 'cButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        if (['AnnouncementNode', 'VariableNode', 'SurveyNode', 'QuestionNode', 'HTTPNode'].includes(item.type)) {
          console.log("item", item);
          for (const key of item.options) {
            if (key[0] == 'cPlayButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        for (const key of item.options) {

          if (['DTMF Digit', 'Value', 'Text'].includes(key[0]) && this.editor.nodes.find((e) => e.id == item.id).options.get(key[0])) {
            this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
          }
        }
      }
    },
    goToNode(node) {
      this._ivr_object = this.editor.save();

      console.log("node.position", node.position);
      this.editor.load({ ...this.editor.save(), panning: { x: -1 * node.position.x, y: -1 * node.position.y } });
      for (const item of this._ivr_object.nodes) {
        if (item.type == 'HTTPNode') {
          console.log("item", item);
          for (const key of item.options) {
            console.log("key", key);
            if (['Header', 'Body'].includes(key[0])) {
              console.log("this.editor.nodes.find((e) => e.id == item.id)", this.editor.nodes.find((e) => e.id == item.id));
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
            }
            else if (key[0] == 'cButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        if (['AnnouncementNode', 'VariableNode', 'SurveyNode', 'QuestionNode', 'HTTPNode'].includes(item.type)) {
          console.log("item", item);
          for (const key of item.options) {
            if (key[0] == 'cPlayButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        for (const key of item.options) {

          if (['DTMF Digit', 'Value', 'Text'].includes(key[0]) && this.editor.nodes.find((e) => e.id == item.id).options.get(key[0])) {
            this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
          }
        }
      }
    },
    flattenObject(ob) {
      var toReturn = {};
      if (Array.isArray(ob)) {
        toReturn['length'] = ob.length
      }

      for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
          var flatObject = this.flattenObject(ob[i]);
          for (var x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + '.' + x] = flatObject[x];
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
      return toReturn;
    },
    async request_verify(item) {
      console.log("request_verify", item);
      console.log("this.$http_ex", this.$http_ex);
      if (item.name == 'http') {
        const options = {
          method: item.type,
          url: item.url,
          data: JSON.parse(item.body),
          headers: JSON.parse(item.headers),
          // params: params
        };

        console.log("this.$http_ex", options);
        let response;
        try {
          response = await this.$http_ex(options);
          this.$swal({
            title: "Response",
            width: 800,
            html: '<pre style="text-align:left">' + JSON.stringify(this.flattenObject({ [item.variable]: response.data }), ' ', 2) + '</pre>',
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          console.log("Response:", response.data);
        } catch (error) {
          console.log("Error:", error.response.data);
          this.$swal({
            title: "Response",
            text: JSON.stringify(this.flattenObject({ [item.variable]: error.response.data }), ' ', 2),
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        console.log(item);
      }

    },
    async SendRequest(_type, _url, _body, _headers, _variable) {
      const options = {
        method: _type,
        url: _url,
        data: JSON.parse(_body),
        headers: JSON.parse(_headers),
        // params: params
      };

      console.log("this.$http_ex", options);
      let response;
      try {
        response = await this.$http_ex(options);
        this.$swal({
          title: "Response",
          width: 800,
          html: '<pre style="text-align:left">' + JSON.stringify(this.flattenObject({ [_variable]: response.data }), ' ', 2) + '</pre>',
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log("Response:", response.data);
      } catch (error) {
        console.log("Error:", error.response.data);
        this.$swal({
          title: "Response",
          text: JSON.stringify(this.flattenObject({ [_variable]: error.response.data }), ' ', 2),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    async doCopy(text) {
      let _text = `{{${text}}}`;
      await navigator.clipboard.writeText(_text);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Metin kopyalandı.",
          icon: "BellIcon",
        },
      });
    },
    GetSmsSettings: async function () {
      var response = (await this.$http_in.get(`/smgr/v1/SmsSettings`)).data;
      this.sms_settings = response;
      this.IVRElements.SMSNode = formatData(response);

    },
    GetEMailTempaltes: async function () {
      var response = (await this.$http_in.get(`/smgr/v1/EMailTemplate`)).data;
      this.IVRElements.EMailTemplatesNode = formatData(response);

    },
    GetEMailSettings: async function () {
      var response = (await this.$http_in.get(`/smgr/v1/EMailSettings`)).data;
      this.email_settings = response;
      this.IVRElements.EMailProvidersNode = formatData(response);

    },
    on_change_ivr(item) {
      this.selected_item.target = {
        internal_name: this.temp.internal_name,
        display_name: this.temp.display_name,

      };

      // item = { "internal_name": this.temp.internal_name, "display_name": this.temp.display_name };
    },
    editItem(item) {
      console.log("this is from parent:", item);
      this.selected_item = item;
      if (item.name == 'ivr' && item.target && item.target.internal_name != '') {
        this.temp = this.ivr.find(e => e.internal_name == item.target.internal_name);
      }
      console.log("this.selected_item", this.selected_item);
      this.edit_modal = true;
      console.log(" this.modal", this.edit_modal);
    },
    clearAll() {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.list = [];
        }
      });
      // console.log("ivr_info", this.ivr_info);
      // var _list = this.ivr_info.ivr_object_baklava.slice();
      // this.list = _list;
    },
    async updateSelected() {
      console.log("updateSelected", this.ivr_info);
      if (this.ivr_info.internal_name == "new_record") {
        this.edit = false;
        this.list = [];
        try {
          this.editor.load();

        } catch (error) {

        }
        this.editor.addNode(new StartNode());

        return;
      }
      this.is_progress = true;
      var ivr_details = (await this.$http_in.get(`/smgr/v1/Ivr/${this.ivr_info.internal_name}`)).data;
      if (!ivr_details.hasOwnProperty('ivr_object_baklava')) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: 'Eski IVR görüntülenemiyor.',
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.is_progress = false;
        return;
      }

      this.ivr_info.ivr_object_baklava = ivr_details.ivr_object_baklava ?? [];
      this.edit = true;
      this._ivr_object = JSON.parse(this.ivr_info.ivr_object_baklava);
      globalThis.editor.load(this._ivr_object);

      for (const item of this._ivr_object.nodes) {
        if (item.type == 'HTTPNode') {
          console.log("item", item);
          for (const key of item.options) {
            console.log("key", key);
            if (['Header', 'Body'].includes(key[0])) {
              console.log("this.editor.nodes.find((e) => e.id == item.id)", this.editor.nodes.find((e) => e.id == item.id));
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
            }
            else if (key[0] == 'cButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }
        if (['AnnouncementNode', 'VariableNode', 'SurveyNode', 'QuestionNode'].includes(item.type)) {
          console.log("item", item);
          for (const key of item.options) {
            if (key[0] == 'cPlayButton') {
              this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = item.id;
            }
          }
        }

        for (const key of item.options) {

          if (['DTMF Digit', 'Value', 'Text'].includes(key[0]) && this.editor.nodes.find((e) => e.id == item.id).options.get(key[0])) {
            this.editor.nodes.find((e) => e.id == item.id).options.get(key[0]).value = key[1];
          }
        }
      }
      this.is_progress = false;


      // var _list = this.ivr_info.ivr_object_baklava.slice();
      // this.list = _list;
    },
    cloneItem(item) {
      console.log(item);
      return { ...item, tasks: [], tasks2: [] };
    },
    get_line_groups: async function () {
      var response = (await this.$http_in.get(`/smgr/v1/LineGroup`)).data;
      // console.log("LineGroup", response);
      this.line_groups = response;
      this.IVRElements.LineGroupNode = formatData(response);

    },
    async get_records() {
      this.is_progress = true;
      var response = (await this.$http_in.get(`smgr/v1/IvrManagementTargets`))
        .data;

      this.options = [
        {
          internal_name: "new_record",
          display_name: "",
          ivr_object: [],
        },
        ...response.Ivr,
      ];

      this.tc = response.TimeConditions.map(e => { return { internal_name: e.internal_name, display_name: e.display_name } });
      this.ivr = response.Ivr.map(e => { return { internal_name: e.internal_name, display_name: e.display_name } });
      this.queues = response.Queues.map(e => { return { internal_name: e.internal_name, display_name: e.display_name } });
      this.announcements = response.Announcement.filter(
        (e) => !e.is_hold_sound
      ).map(e => { return { internal_name: e.internal_name, display_name: e.display_name } });
      this.local_users = response.LocalUsers.filter(
        (e) => e.type == "extension"
      );
      this.voice_mail_users = response.LocalUsers.filter(
        (e) => e.email
      );
      console.log("this.voice_mail_users", this.voice_mail_users)
      this.surveys = response.Surveys;
      this.tts_providers = response.TTSProviders;
      this.stt_providers = response.STTProviders ?? [];


      this.IVRElements = {
        TimeConditionNode: formatData(response.TimeConditions.map(e => { return { internal_name: e.internal_name, display_name: e.display_name } })),
        AnnouncementNode: formatData(response.Announcement.map(e => { return { internal_name: e.internal_name, display_name: e.display_name } })),
        IVRNode: formatData(response.Ivr.map(e => { return { internal_name: e.internal_name, display_name: e.display_name } })),
        ExtensionNode: formatData(response.LocalUsers.filter((e) => e.type == "extension")),
        EMailNode: formatData(response.LocalUsers.filter((e) => e.email)),
        SurveyNode: formatData(response.Surveys),
        TTSNode: formatData(response.TTSProviders),
        STTNode: formatData(response.STTProviders),
        QueueNode: formatData(response.Queues.map(e => { return { internal_name: e.internal_name, display_name: e.display_name } })),
        Operand: formatData(this.operands),
      };

      globalThis.IVRElements = this.IVRElements;
      globalThis.ivr_targets = response;
      this.is_progress = false;




    },
    set_record: async function () {
      if (this.ivr_info.display_name == "") {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.ivr_info = {
        ...this.ivr_info,
        ivr_object_baklava: JSON.stringify(globalThis.editor.save()),
        // ivr_object: this.list,
      };

      this.is_progress = true;
      var response;
      if (!this.edit) {
        response = (
          await this.$http_in.post(`smgr/v1/Ivr`, {
            data: this.ivr_info,
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`smgr/v1/Ivr`, {
            data: this.ivr_info,
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.get_records();
    },
    async copy_as_new_ivr() {
      if (this.copy_name == "") {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      let _ivr_info = {
        display_name: this.copy_name,
        ivr_object_baklava: JSON.stringify(globalThis.editor.save()),
      };

      this.is_progress = true;
      var response;
      response = (
        await this.$http_in.post(`smgr/v1/Ivr`, {
          data: _ivr_info,
        })
      ).data;

      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.get_records();
    },
    delete_request() {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    delete_record: async function () {
      try {
        this.is_progress = true;
        this.modal_delete = false;
        var response = (
          await this.$http_in.delete(`smgr/v1/Ivr/${this.ivr_info.internal_name}`)
        ).data;
        if (response && response.acknowledged) {
          this.$swal({
            icon: "success",
            title: this.lang("t_processSuccess"),
            text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
            confirmButtonText: this.lang("t_ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      }
      catch (error) {
        if (error && error.response && error.response.data) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: `${error.response.data} kullanıldığı için kayıt silinemiyor.`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } finally {
        this.is_progress = false;
      }

      this.create_new_ivr();
      this.get_records();
    },
    create_new_ivr() {
      this.edit = false;
      this.ivr_info = {};
      this.list = [];
    },
  },
  mounted: async function () {
    globalThis.ivr = this;
    await this.get_records();
    this.get_line_groups();
    this.GetSmsSettings();
    this.GetEMailSettings();
    this.GetEMailTempaltes();
    this.editor.load({ ...this.editor.save(), scaling: 0.65 });

    this.interval = setInterval(() => {
      globalThis.ivr.setScaleInterval();
    }, 2000);
  },
  beforeDestroy: function () {

    clearInterval(this.interval);
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      modal_copy: false,
      copy_name: '',
      _ivr_object: null,
      interval: null,
      editor_scale: 65,
      editor: new Editor(),
      viewPlugin: new ViewPlugin(),
      IVRElements: {},
      operands: [{ internal_name: "equal", display_name: "Equal" }, { internal_name: "not_equal", display_name: "Not Equal", }, { internal_name: "less_than", display_name: "Less Than", }, { internal_name: "greater_than", display_name: "Greater Than", }, { internal_name: "less_than_or_equal", display_name: "Less Than or Equal", }, { internal_name: "greater_than_or_equal", display_name: "Greater Than or Equal", }, { internal_name: "is_in_list", display_name: "Is In List", }, { internal_name: "is_not_in_list", display_name: "Is Not In List", }, { internal_name: "is_contains", display_name: "Is Contains", }, { internal_name: "is_not_contains", display_name: "Is Not Contains", }],
      is_progress: false,
      temp: {},
      ivr_info: {
        internal_name: "new_record",
        display_name: "",
        ivr_object: [],
        ivr_object_baklava: [],
      },
      selected_item: {},
      edit_modal: false,
      line_groups: [],
      sms_settings: [],
      email_settings: [],
      operands: [
        { internal_name: "equal", display_name: globalThis._lang("t_equal") },
        {
          internal_name: "not_equal",
          display_name: globalThis._lang("t_notEqual"),
        },
        {
          internal_name: "less_than",
          display_name: globalThis._lang("t_lessThan"),
        },
        {
          internal_name: "greater_than",
          display_name: globalThis._lang("t_greaterThan"),
        },
        {
          internal_name: "less_than_or_equal",
          display_name: globalThis._lang("t_lessThanOrEqual"),
        },
        {
          internal_name: "greater_than_or_equal",
          display_name: globalThis._lang("t_moreThanOrEqual"),
        },
        {
          internal_name: "is_in_list",
          display_name: globalThis._lang("t_isInList"),
        },
        {
          internal_name: "is_not_in_list",
          display_name: globalThis._lang("t_isNotInList"),
        },
        {
          internal_name: "is_contains",
          display_name: globalThis._lang("t_isContains"),
        },
        {
          internal_name: "is_not_contains",
          display_name: globalThis._lang("t_isNotContains"),
        },
        {
          internal_name: "is_convergent",
          display_name: globalThis._lang("t_IsConvergent"),
        },
        {
          internal_name: "is_not_convergent",
          display_name: globalThis._lang("t_IsNotConvrgent"),
        },
      ],
      options: [
        // {
        //   internal_name: 'new_record',
        //   display_name: '',
        //   ivr_object: [],
        // },
      ],
      languages: [
        {
          internal_name: "tr-TR",
          display_name: globalThis._lang("t_turkish"),
        },
        {
          internal_name: "en-EN",
          display_name: globalThis._lang("t_english"),
        },
        {
          internal_name: "ar-AR",
          display_name: globalThis._lang("t_arabic"),
        },
        {
          internal_name: "ru-RU",
          display_name: globalThis._lang("t_russian"),
        },
      ],
      tc: [],
      ivr: [],
      queues: [],
      announcements: [],
      surveys: [],
      tts_providers: [],
      stt_providers: [],
      local_users: [],
      voice_mail_users: [],
      list: [],
      list1: [
        {
          name: "tc",
          display_name: globalThis._lang("t_timeCondition"),
          tasks: [],
          tasks2: [],
          is_dtmf: false,
          dtmf: "",
          target: null,
        },
        {
          name: "menu",
          display_name: globalThis._lang("t_menu"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
          timeout: "",
          timeout_retry: "",
          retry: "",
          language: "",
          can_recieve_exten: "",
        },
        {
          name: "announce",
          display_name: globalThis._lang("t_announcement"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
          can_recieve_dtmf: false,
          target: { internal_name: "", display_name: "" },
        },
        {
          name: "ivr",
          display_name: globalThis._lang("t_IVR"),
          tasks: [],
          is_dtmf: true,
          dtmf: "",
          target: {
            internal_name: "",
            display_name: "",
          },
        },
        {
          name: "queue",
          display_name: globalThis._lang("t_queue"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
          target: { internal_name: "", display_name: "" },
        },
        {
          name: "exten",
          display_name: globalThis._lang("t_extension"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
          type: "exten",
          mail_settings: {},
          target: { internal_name: "", display_name: "" },
        },
        {
          name: "survey",
          display_name: globalThis._lang("t_survey"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
          can_recieve_dtmf: false,
          announcement: "",
          target: { internal_name: "", display_name: "" },
        },
        {
          name: "condition",
          display_name: globalThis._lang("t_condition"),
          description: '',
          tasks: [],
          tasks2: [],
          is_dtmf: false,
          dtmf: "",
          condition: {
            value1: "",
            value2: "",
            operand: "",
          },
        },
        {
          name: "variable",
          display_name: globalThis._lang("t_variable"),
          description: '',
          value: '',
          is_set_attribute: false,
          is_dtmf: false,
          dtmf: "",
          timeout: "3",
          retry: "2",
          announcement: "",
          variable_length: 6,
          variable: "",
          hide_variable_from_agent: false,
        },
        {
          name: "http",
          display_name: globalThis._lang("t_httpRequest"),
          description: '',
          is_dtmf: false,
          dtmf: "",
          timeout: "6",
          retry: null,
          announcement: null,
          variable: "",
          type: "GET",
          url: "",
          body: `{\n"RemoteNumber":"{{RemoteNumber}}",\n"InteractionID":"{{InteractionID}}"\n}`,
          headers: `{\n}`,
          hide_variable_from_agent: false,
        },
        {
          name: "tts",
          display_name: globalThis._lang("t_tts"),
          description: '',
          is_dtmf: false,
          dtmf: "",
          type: null,
          message: "",
        },
        {
          name: "stt",
          display_name: globalThis._lang("t_stt"),
          description: '',
          is_dtmf: false,
          dtmf: "",
          target: {},
          timeout: "6",
          variable: "",
          beep_tone: false

        },
        {
          name: "sms",
          display_name: globalThis._lang("t_sms"),
          description: '',
          is_dtmf: false,
          dtmf: "",
          sms_settings: {},
          message: "",
          remote_number: "",
        },

        {
          name: "transfer",
          display_name: globalThis._lang("t_transfer"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
          line_group: {},
          target: { internal_name: "", display_name: "" },
        },
        {
          name: "hangup",
          display_name: globalThis._lang("t_hangup"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
        },

        // {
        //   name: "voice_mail",
        //   display_name: "Sesli Mesaj",
        //   tasks: [],
        //   is_dtmf: true,
        //   dtmf: "",
        //   target: { internal_name: "", display_name: "" },
        // },
      ],
    };
  },
};
</script>

<style  lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-slider.scss";

.swal-modal .swal-text {
  text-align: left;
}

#editor {
  height: 65vh;
}

.background {
  background-position: left 0px top 0px;
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-color: #ececeb !important;
}
.node.--type-StartNode .__content {
  background-color: #48da89;
}
.node.--type-HangupNode .__content {
  background-color: #f08182;
}
.node.--type-TimeConditionNode .__title {
  background-color: #4839eb;
}

.node.--type-ConditionNode .__title {
  background-color: #9ca0a4;
}
.node.--type-VariableNode .__title {
  background-color: #28c76f;
}

.node.--type-SetAttributeNode .__title {
  background-color: #7367f0;
}
.node.--type-AssignedAgentNode .__title {
  background-color: #00cfe8;
}

.node.--type-ExtensionsNode .__title {
  background-color: #ff9f43;
}
.node.--type-QueueNode .__title {
  background-color: #1be7ff;
}

.node.--type-IVRNode .__title {
  background-color: #ffb976;
}
.node.--type-MailNode .__title {
  background-color: #ea5455;
}

.node.--type-SMSNode .__title {
  background-color: #82868b;
}
.node.--type-MenuNode .__title {
  background-color: #9e95f5;
}

.node.--type-HTTPNode .__title {
  background-color: #f08182;
}
.node.--type-AnnouncementNode .__title {
  background-color: #48da89;
}

.node.--type-TTSNode .__title {
  background-color: #1f9d57;
}
.node.--type-STTNode .__title {
  background-color: #01a1b5;
}

.node.--type-SurveyNode .__title {
  background-color: #ff8510;
}
.node.--type-TransferNode .__title {
  background-color: #696d71;
}

.node.--type-VoiceMailNode .__title {
  background-color: #e42728;
}

.hint {
  position: absolute;
  bottom: 0;
  // margin-bottom: 3rem;
  // right: 100%;
  float: left;
  z-index: 5;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  color: white;
  // pointer-events: none;
}
</style>

<style>
.d-menu-class {
  max-height: 30vh;
  overflow-y: auto;
}

.slide-fade-enter-active {
  transition: all 0.15s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.15s ease-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(5px);
  opacity: 0;
}

.dark-foreground {
  color: #fff;
  fill: #fff;
  stroke: #fff;
}

.dark-button {
  background-color: #2c2c2c;
  color: #fff;
  transition: background-color 0.1s linear;
  border: none;
  padding: 0.45em 0.35em;
  border-radius: 3px;
  font-size: inherit;
  cursor: pointer;
}

.dark-button:hover {
  background-color: #4c4c4c;
}

.dark-button:active {
  background-color: #5379b5;
}

.dark-button.--block {
  width: 100%;
}

.dark-checkbox {
  display: flex;
  padding: 0.35em 0;
  cursor: pointer;
}

.dark-checkbox .__checkmark-container {
  display: flex;
  background-color: #2c2c2c;
  border-radius: 3px;
  transition: background-color 0.1s linear;
}

.dark-checkbox:hover .__checkmark-container {
  background-color: #4c4c4c;
}

.dark-checkbox:active .__checkmark-container {
  background-color: #6c6c6c;
}

.dark-checkbox .__checkmark {
  stroke-dasharray: 15;
  stroke-dashoffset: 15;
  stroke: #fff;
  stroke-width: 2px;
  fill: none;
  transition: stroke-dashoffset 0.1s linear;
}

.dark-checkbox.--checked .__checkmark {
  stroke-dashoffset: 0;
}

.dark-checkbox.--checked .__checkmark-container {
  background-color: #5379b5;
}

.dark-context-menu {
  color: #fff;
  position: absolute;
  display: inline-block;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.65);
  border-radius: 0 0 3px 3px;
  min-width: 100px;
}

.dark-context-menu > .item {
  padding: 0.35em 1em;
  transition: background 0.05s linear;
  position: relative;
}

.dark-context-menu > .item.--disabled {
  color: #4c4c4c;
}

.dark-context-menu
  > .item:not(.--header):not(.--active):not(.--disabled):hover {
  background: #5379b5;
}

.dark-context-menu > .item.--active {
  background: #5379b5;
}

.dark-context-menu.--nested {
  left: 100%;
  top: 0;
}

.dark-context-menu.--flipped-x.--nested {
  left: unset;
  right: 100%;
}

.dark-context-menu.--flipped-y.--nested {
  top: unset;
  bottom: 0;
}

.dark-context-menu > .divider {
  margin: 0.35em 0;
  height: 1px;
  background-color: #4c4c4c;
}

.dark-input {
  background-color: #2c2c2c;
  color: #fff;
  caret-color: #5379b5;
  border: none;
  border-radius: 3px;
  padding: 0.45em 0.75em;
  width: 100%;
  transition: background-color 0.1s linear;
  font-size: inherit;
  font: inherit;
}

.dark-input:hover {
  background-color: #4c4c4c;
}

.dark-input:active {
  background-color: #6c6c6c;
}

.dark-input[type="number"]::-webkit-inner-spin-button,
.dark-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dark-input.--invalid {
  box-shadow: 0 0 2px 2px red;
}

.dark-select {
  width: 100%;
  position: relative;
  color: #fff;
}

.dark-select.--open > .__selected {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dark-select.--open > .__selected > .__icon {
  transform: rotate(180deg);
}

.dark-select > .__selected {
  background-color: #2c2c2c;
  padding: 0.35em 0.75em;
  border-radius: 3px;
  transition: background 0.1s linear;
  min-height: 1.7em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dark-select > .__selected:hover {
  background: #4c4c4c;
}

.dark-select > .__selected:active {
  background: #6c6c6c;
}

.dark-select > .__selected > .__text {
  flex: 1 0 auto;
  flex-basis: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dark-select > .__selected > .__icon {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.25s ease;
  width: 18px;
  height: 18px;
}

.dark-select > .__selected > .__icon path {
  stroke: #fff;
  fill: #fff;
}

.dark-select > .__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6666666667);
  filter: drop-shadow(0 0 4px black);
  border-radius: 0 0 3px 3px;
  max-height: 15em;
  overflow-y: scroll;
}

.dark-select > .__dropdown::-webkit-scrollbar {
  width: 0px;
  background: rgba(0, 0, 0, 0);
}

.dark-select > .__dropdown > .item {
  padding: 0.35em;
  padding-left: 1em;
  transition: background 0.05s linear;
}

.dark-select > .__dropdown > .item:not(.--header):not(.--active) {
  cursor: pointer;
}

.dark-select > .__dropdown > .item:not(.--header):not(.--active):hover {
  background: #4c4c4c;
}

.dark-select > .__dropdown > .item.--active {
  background: #5379b5;
}

.dark-select > .__dropdown > .item.--header {
  color: gray;
  border-bottom: 1px solid gray;
  padding: 0.5em 0.35em;
  padding-left: 1em;
}

.dark-slider {
  background: #2c2c2c;
  color: #fff;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

.dark-slider > .__content {
  display: flex;
  position: relative;
  z-index: 1;
}

.dark-slider > .__content > .__label,
.dark-slider > .__content > .__value {
  flex: 1 1 auto;
  margin: 0.35em 0;
  padding: 0 0.5em;
  text-overflow: ellipsis;
}

.dark-slider > .__content > .__value {
  text-align: right;
}

.dark-slider > .__content > input {
  background-color: #2c2c2c;
  color: #fff;
  caret-color: #5379b5;
  padding: 0.35em;
  width: 100%;
}

.dark-slider > .__slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #5379b5;
  border-radius: 3px;
}

.dark-num-input {
  background: #2c2c2c;
  color: #fff;
  border-radius: 3px;
  display: flex;
}

.dark-num-input > .__button {
  display: flex;
  flex: 0 0 auto;
  width: 20px;
  justify-content: center;
  align-items: center;
  transition: background 0.1s linear;
  cursor: pointer;
}

.dark-num-input > .__button:hover {
  background-color: #4c4c4c;
}

.dark-num-input > .__button:active {
  background-color: #6c6c6c;
}

.dark-num-input > .__button.--dec > svg {
  transform: rotateZ(90deg);
}

.dark-num-input > .__button.--inc > svg {
  transform: rotateZ(-90deg);
}

.dark-num-input > .__button path {
  stroke: #fff;
  fill: #fff;
}

.dark-num-input > .__content {
  display: flex;
  flex: 1 1 auto;
  cursor: pointer;
}

.dark-num-input > .__content:hover {
  background-color: #4c4c4c;
}

.dark-num-input > .__content:active {
  background-color: #6c6c6c;
}

.dark-num-input > .__content > .__label,
.dark-num-input > .__content > .__value {
  flex: 1 1 auto;
  margin: 0.35em 0;
  padding: 0 0.5em;
  text-overflow: ellipsis;
}

.dark-num-input > .__content > .__value {
  text-align: right;
}

.dark-num-input > .__content > input {
  background-color: #2c2c2c;
  color: #fff;
  caret-color: #5379b5;
  padding: 0.35em;
  width: 100%;
}

.node {
  max-width: 20rem;
  background: rgba(63, 63, 63, 0.8);
  color: #fff;
  border-radius: 4px;
  position: absolute;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
  transition: box-shadow 0.1s linear, filter 0.1s linear;
}

.node:hover {
  box-shadow: 0 0 0 0.35px #5379b5;
}

.node.--selected {
  z-index: 5;
  box-shadow: 0 0 0 1px #5379b5;
}

.node.--dragging {
  filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.8));
}

.node.--dragging > .__title {
  cursor: grabbing;
}

.node > .__title {
  background: #000;
  color: #fff;
  padding: 0.4em 0.75em;
  border-radius: 4px 4px 0 0;
  cursor: grab;
}

.node > .__title > span {
  pointer-events: none;
}

.node > .__content {
  padding: 0.75em;
}

.node > .__content > div > div {
  margin: 0.5em 0;
}

.node.--two-column > .__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: ". ." ". .";
}

.node.--two-column > .__content > .__inputs {
  grid-row: 1;
  grid-column: 1;
}

.node.--two-column > .__content > .__outputs {
  grid-row: 1;
  grid-column: 2;
}

.node.--two-column > .__content > .__options {
  grid-row: 2;
  grid-column: 1 / span 2;
}

.node-interface {
  padding: 0.25em 0;
  position: relative;
}

.node-interface .__port {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  top: calc(50% - 5px);
  cursor: crosshair;
}

.node-interface.--input {
  text-align: left;
  padding-left: 0.5em;
}

.node-interface.--input .__port {
  left: -1.1em;
}

.node-interface.--output {
  text-align: right;
  padding-right: 0.5em;
}

.node-interface.--output .__port {
  right: -1.1em;
}

.connection {
  stroke: #7367f0;
  stroke-width: 2px;
  fill: none;
}

.connection.--temporary {
  stroke-width: 4px;
  stroke-dasharray: 5 5;
  stroke-dashoffset: 0;
  animation: dash 1s linear infinite;
  transform: translateY(-1px);
  stroke-linecap: round;
}

@keyframes dash {
  to {
    stroke-dashoffset: 20;
  }
}

.connection.--allowed {
  stroke: #28c76f;
}

.connection.--forbidden {
  stroke: #ea5455;
}

.sidebar {
  position: absolute;
  height: 100%;
  width: 25%;
  min-width: 300px;
  max-width: 90%;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #3f3f3f;
  color: #fff;
  box-shadow: none;
  overflow-x: hidden;
  padding: 1em;
  transform: translateX(100%);
  transition: transform 0.5s;
}

.sidebar.--open {
  transform: translateX(0);
  box-shadow: 0px 0px 15px #000;
}

.sidebar .__resizer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  cursor: col-resize;
}

.sidebar .__close {
  font-size: 2em;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
}

.minimap {
  position: absolute;
  height: 15%;
  width: 15%;
  min-width: 150px;
  max-width: 90%;
  top: 20px;
  right: 20px;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.node-editor {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  outline: none !important;
  font-family: "Lato", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
}

/* .node-editor .background {
  background-color: #ececeb;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.1333333333) 2px,
      transparent 2px
    ),
    linear-gradient(90deg, rgba(0, 0, 0, 0.1333333333) 2px, transparent 2px),
    linear-gradient(#ececeb 1px, transparent 1px),
    linear-gradient(90deg, #ececeb 1px, transparent 1px);
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  pointer-events: none !important;
} */

.node-editor .background {
  background-color: #8e92c8;
  background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1333333333) 2px,
      transparent 2px
    ),
    radial-gradient(circle, rgba(0, 0, 0, 0.07777777777) 2px, transparent 2px),
    radial-gradient(circle, rgba(0, 0, 0, 0.1333333333) 1px, transparent 1px),
    radial-gradient(circle, rgba(0, 0, 0, 0.1333333333) 1px, transparent 1px);
  background-size: 10px 10px;
  width: 100%;
  height: 100%;
  pointer-events: none !important;
}

.node-editor *:not(input):not(textarea) {
  user-select: none;
  -moz-user-select: none;
}

.node-editor .input-user-select {
  user-select: auto;
  -moz-user-select: auto;
}

.node-editor *,
.node-editor *::after,
.node-editor *::before {
  box-sizing: border-box;
}

.node-editor.--temporary-connection {
  cursor: crosshair;
}

.ignore-mouse * {
  pointer-events: none !important;
}

.ignore-mouse .__port {
  pointer-events: all !important;
}

.connections-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none !important;
}

.node-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.node-container * {
  pointer-events: all;
}

.overlay {
  z-index: 100;
}

/* .dark-context-menu {
  max-height: calc(100% - 50px) !important;
  overflow-y: auto !important;
} */

.--nested {
  left: 100%;
  top: 0;
  height: 300px;
  overflow-y: auto;
}
</style>
