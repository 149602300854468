import { Node } from "@baklavajs/core";

export class HTTPNode extends Node {
  twoColumn = true;
  width = 1200;
  constructor() {
    super();

    this.type = "HTTPNode";
    this.name = "HTTP";
    // this.addOption("DTMF Digit", "IntegerOption", -1);

    this.addOption("Variable", "InputOption", "", undefined, { displayName: "Variable" });
    this.addOption("Announcement", "SelectOption", '', undefined, {
      items: globalThis.IVRElements.AnnouncementNode,
      displayName: "Announcement",
    })
    this.addOption("Hide From Agent", "CheckboxOption")
    // this.addOption("Show In Call Details", "CheckboxOption")

    this.addOption("Type", "SelectOption", "GET", undefined, {
      items: ["GET", "POST", "PUT", "DELETE"],
    });
    this.addOption("URL", "InputOption", "", undefined, { displayName: "URL" });
    this.addOption("Header", "TextArea", "Header", "Header", { displayName: "Header" });
    // this.addOption("Body", "InputOption", "", undefined, { displayName: "Body" });
    this.addOption("Body", "TextArea", "Body", 'Body', { displayName: "Body" });
    this.addOption("cButton", "cButton", this.id);
    this.addOption("cPlayButton", "cPlayButton", this.id);

    this.addInputInterface("In");
    this.addOutputInterface("Next");
    console.log("thisId", this.id);
  }
}
